import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import addEditAudit from './addEditAudit';
import { format } from 'date-fns';
import auditDetails from './auditDetails';
import assetScanDetails from './assetScanDetails';

export default {
  name: 'assetAudit',
  components: {
    appStrings,
    DatePicker,
    addEditAudit,
    auditDetails,
    assetScanDetails
  },
  props: ['currentLoc', 'locId', 'faId','locAccessFlag'],
  watch: {
    currentPage: function() {
      this.getAuditHistory();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAuditHistory();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      showValueSetModal: false,
      showHideAssetAuditModalFlag: false,
      showHideAuditDetailsModalFlag: false,
      responseMsg: '',
      isSuccess: false,
      currentLocation: {
        text: null,
        value: null
      },
      assetAuditData: [
        // {
        //   start_date: format(new Date(), 'dd-MMM-yyyy'),
        //   end_date: format(new Date(), 'dd-MMM-yyyy'),
        //   initiated_by: 'Connaissant',
        //   status:'Completed'
        // }
      ],
      assetAuditFields: [
        {
          key: 'created_by_id',
          label: 'Created By'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'status',
        },
        {
          key: 'audit_details'
        },
        {
          key: 'scan_details'
        },
        {
          key:'last_updation_date',
          class:'d-none'
        },
        // {
        //   key: 'scanned_status'
        // }
      ],
      locFlag: false,
      audFlag: false,
      showHideAuditScanDetailsModalFlag: false,
      faLocation: {
        text: null,
        value: null
      },
      rowData: null,
      faLoc: [],
      auditData: null,
      accessButtons:{},
      showHideAlertModalFlag:false,
      getData:null,
      auditDate:[],
      startDate:new Date(new Date().setMonth(new Date().getMonth() - 6)),
      endDate:new Date(),
      auditStatus: {
        text: null,
        value: null
      },
    };
  },
  mounted() {
    this.setDefaultFALocation();
    if (this.currentLoc) {
      this.locFlag = true;
      this.currentLocation.text = this.currentLoc.text;
      this.currentLocation.value = this.currentLoc.value;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'add' &&
          !this.showHideAuditDetailsModalFlag &&
          !this.showHideAuditScanDetailsModalFlag
        ) {
          if (this.currentLocation.text && this.faLocation.text) {
            this.showHideAssetAuditModal(true);
          } else {
            alert('Kindly select a location from search parameter');
          }
        }
      }
    });
  },
  // validations: {
  //   currentLocation: {
  //     text: {
  //       required
  //     }
  //   },
  // },
  created() {
    this.eventBus.$on('auditFlag', auditFlag => {
      this.audFlag = auditFlag;
    });
  },
  methods: {
    setDefaultFALocation() {
      const location = commonHelper.getValuesetDataAsset(
        appStrings.VALUESETTYPE.ASSET_LOCATION,
        this.locId
      );
      location.then(key => {
        if (key.length == 1) {
          this.faLocation.text = key[0].value_code;
          this.faLocation.value = key[0].value_set_dtl_id;
          this.getAuditHistory();
        }else{
          this.getAuditHistory();
        }
      });
    },
    getAuditHistory() {
      const payload = {
        locId: Number(this.locId),
        queryParams:{
          _page: this.currentPage - 1,
          _limit: this.perPage,
          faloc:this.faLocation.value,
          start_date: commonHelper.formattedDate(this.auditDate[0])?commonHelper.formattedDate(this.auditDate[0]):format(this.startDate,'dd-MMM-yyyy'),
          end_date: commonHelper.formattedDate(this.auditDate[1])?commonHelper.formattedDate(this.auditDate[1]):format(this.endDate,'dd-MMM-yyyy'),
          audit_status:this.auditStatus.value
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAuditData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            // if (loc) {
            //   this.assetAuditData = resp.data.data.page.filter(
            //     ele => ele.location_name == loc,                
            //   );
              // this.assetAuditData.map(ele => {
              //   if (ele.audit_status == 'Initiated') {
              //     ele.scanned_status = '10';
              //   } else if (ele.audit_status == 'Inprocess') {
              //     ele.scanned_status = '60';
              //   } else {
              //     ele.scanned_status = '100';
              //   }
              // });
            // } else {
              this.assetAuditData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
              // this.assetAuditData.map(ele => {
              //   if (ele.audit_status == 'Initiated') {
              //     ele.scanned_status = '10';
              //   } else if (ele.audit_status == 'Inprocess') {
              //     ele.scanned_status = '60';
              //   } else {
              //     ele.scanned_status = '100';
              //   }
              // });
            // }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    showHideAssetAuditModal(flag) {
      this.showHideAssetAuditModalFlag = flag;
      if (!flag) {
        this.getAuditHistory();
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
      
    },

    showHideAuditDetailsModal(flag, data) {
      this.showHideAlertModal(false)
      if (flag) {
        this.auditData = data;
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
      if(!flag){
        this.getAuditHistory();
      }
      this.showHideAuditScanDetailsModalFlag = false;
      this.showHideAuditDetailsModalFlag = flag;
      this.audFlag = false;
    },
    openValueSetModal(vsetCode) {
      this.parent_value_set_id = null;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.parent_value_set_id = this.locId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.currentLocation = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.faLocation = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
        this.faLoc = this.faLocation.text.split('.');
        this.getAuditHistory(this.faLoc[1]);
      } else if (this.vsetCode === appStrings.VALUESETTYPE.AUDIT_STATUS) {
        this.auditStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.currentLocation = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_LOCATION) {
        this.faLocation = {
          text: null,
          value: null
        };
        this.faLoc = [];
        this.getAuditHistory();
      }
      else if (this.vsetCode === appStrings.VALUESETTYPE.AUDIT_STATUS) {
        this.auditStatus = {
          text: null,
          value: null
        };
      }
    },
    clearData() {
      this.faLocation = {
        text: null,
        value: null
      };
      this.auditStatus = {
        text: null,
        value: null
      };
      this.auditDate=[]
    },
    showHideAuditScanDetailsModal(flag, item) {
      this.showHideAuditDetailsModalFlag = false;
      this.showHideAuditScanDetailsModalFlag = flag;
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
      this.audFlag = false;
      this.rowData = item;
    },
    showHideAlertModal(flag,item){
      this.showHideAlertModalFlag=flag
      this.getData=item
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('auditFlag');
  }
};
